import React from "react";

export default props => (
  <div className="subHeader">

    {/* Hi I'm a Bar component with the title: */}
    {/* <h2>{props.title}</h2> */}

    <div className="subHeader--inner">
    <div className="subHeader--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/association1901.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h2>Association loi 1901</h2>
        <p>Objet humanitaire et caritatif avec des valeurs chrétiennes</p>
        </div>
    </div>
    <div className="subHeader--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/mission.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h2>Notre mission</h2>
        <p>Agir en faveur des personnes en situation de précarité</p>
        </div>
    </div>
    <div className="subHeader--item">
        <div className="item-icon">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/intervention.png" alt="JAIDE"/>
        </div>
        <div className="text">
        <h2>Territoires d'intervention</h2>
        <p>Les départements du Val d'Oise (95), les Yvelines (78) et l'Oise (60)</p>
        </div>
    </div>

  </div>
  </div>
);
