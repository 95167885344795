import React from "react";

export default props => (
  <div className="topContentSplit">
     
     <div className="topContentSplit--inner">
        <div className="topContentSplit--left">
            <div className="top-ContentSplit--content">
                <h2>Loi 1901</h2>
                <p>Association JAIDE</p>
                <img src="" alt=""/>
            </div>
        </div>
        <div className="topContentSplit--right">
            <div className="text">
                <ul>
                    <li>
                        <h3>Une association humanitaire et caritative avec des valeurs chrétiennes</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nam rerum assumenda at praesentium ea enim quasi </p>
                    </li>
                    <li>
                        <h3>Association entierement composée de personnes bénévoles, dynamiques et désireuses</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nam rerum assumenda at praesentium ea enim quasi </p>
                    </li>
                    <li>
                        <h3>Contribue de manière pratique aux besoins des populations locales</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita nam rerum assumenda at praesentium ea enim quasi </p>
                    </li>
                </ul>
            </div>
        </div>
     </div>
  </div>
);
