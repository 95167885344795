import React, { useState } from "react";
import Swiper from 'react-id-swiper';
// import Link from 'gatsby-link';


export default (props) => {

  const params = {
    spaceBetween: 30,
    slidesPerView: 3
  }
  const [swiper, updateSwiper] = useState(null);
 
  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };
 
  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  return(
  <div className="left-content-slider">

    <div className="left-content-slider--inner">

            <div className="left-content-slider-static">
                <h2>Autour de JAIDE</h2>
                <p>Retrouver ici toute l'info des </p>
                <div className="buttons">
                  <button onClick={goPrev}>Prev</button>
                  <button onClick={goNext}>Next</button>
                </div>  
            </div>


            <div className="left-content-slider--dynamic">
                {/* <div className="swiper-container"> */}
                  {/* <div> */}

                    <Swiper {...params} getSwiper={updateSwiper}>
                      <div>Slide 1</div>
                      <div>Slide 2</div>
                      <div>Slide 3</div>
                      <div>Slide 4</div>
                      <div>Slide 5</div>
                    </Swiper>
                  {/* </div> */}

                {/* </div> */}
            </div>
    </div>
  </div>
  )
};

// export const pageQuery = graphql`
//   query {
//     allWordpressPage {
//       edges {
//         node {
//           id
//           title
//           excerpt
//           slug
//         }
//       }
//     }
//     allWordpressPost {
//       edges {
//         node {
//           title
//           excerpt
//           slug
//         }
//       }
//     }
//   }
// `
