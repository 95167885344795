import React from "react";
import Link from 'gatsby-link';
import Socialcare from '../img/socialcare.svg';
import Entreprise from '../img/enterprise.svg';
import Global from '../img/global.svg';

// ...later

export default props => (
  <div className="product">


    <div className="product--inner">
    <div className="product--item prochain"> 
        <Link to="/j-aide-mon-prochain">
        <div className="item-icon">
        {/* <img src="http://blog.associationjaide.fr/wp-content/themes/twentytwenty/img/social-care.svg" alt="JAIDE"/> */}
        <img src={Socialcare} alt="JAIDE"/>
        </div>
        <div className="text">
            <h2>J'aide mon prochain</h2>
            <p>Actions solidaires en faveur des personnes en situation de précarité (ou avec des difficultés sociales) </p>
        </div>
        </Link>
    </div>
    <div className="product--item ville">
        <Link to="/j-aide-ma-ville">   
        <div className="item-icon">
        {/* <img src="http://blog.associationjaide.fr/wp-content/themes/twentytwenty/img/enterprise.svg" alt="JAIDE"/> */}
        <img src={Entreprise} alt="JAIDE"/>
        </div>
        <div className="text">
            <h2>J'aide ma ville</h2>
            <p>Actions menées au service communautés locales</p>
            
        </div>
        </Link>
    </div>
    <div className="product--item international">
        <Link to="/j-aide-a-l-international">   
        <div className="item-icon">
        {/* <img src="http://blog.associationjaide.fr/wp-content/themes/twentytwenty/img/global.svg" alt="JAIDE"/> */}
        <img src={Global} alt="JAIDE"/>
        </div>
        <div className="text">
            <h2>J'aide à l'international</h2>
            <p>Actions de solidarité menées dans le monde </p>
        </div>
        </Link>
    </div>

  </div>
  </div>
);
