import React from 'react'
import Link from 'gatsby-link'
import ModalVideo from 'react-modal-video'
import Script from 'react-load-script'


const stylewrapper = {
  width: '100%',
  height: '100%',
  top:0,
  left:0,
  position:'fixed',
  backgroundColor: 'rgba(255,255,255,0.5)',
  zIndex: 1000000,
  cursor: 'pointer',
  opacity: 1,
}

const styleModalVideoBody = {
  maxWidth: '940px',
  width: '100%',
  height: '100%',
  margin: '0 auto',
  display: 'table',
}
const styleVideoInner = {
  display: 'table-cell',
  verticalAlign: 'middle',
  width: '100%',
  height: '100%',
}

const style = {
  // paddingBottom: this.getPadding(this.props.ratio),
  width: '100%',
  height: 0,
  position: 'relative',
  backgroundColor: '#333',
  animationTimingFunction: 'ease-out',
  animationDuration: '.3s',
  animationName:'modal-video-inner',
  transform: 'translate(0, 0)',
  transition: 'transform .3s ease-out',
}

const iframeStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
}
console.log(iframeStyle);
console.log(style);

export class HeaderBranding extends React.Component {
// const HeaderBranding = () => (

  constructor () {
    super()
    this.state = {
      isOpen: false,
      scriptLoaded: false,
      scriptError: false,

    }
    this.openModal = this.openModal.bind(this)
  }

  handleScriptCreate() {
    this.setState({ scriptLoaded: false })
  }
   
  handleScriptError() {
    this.setState({ scriptError: true })
  }
   
  handleScriptLoad() {
    this.setState({ scriptLoaded: true })
  }

  componentDidMount(){
  //   const script = document.createElement("script");
  //   script.async = true;
  //   script.src = "/functions/additionalJS.js";
  //   script.onload = () => this.scriptLoaded();



  //   //For head
    // document.head.appendChild(script);
    // document.head.appendChild(script);
    // styleModalVideoBody
    // styleVideoInner
    // style
    // iframeStyle
    document.getElementsByClassName("modal-video").style = stylewrapper;
    document.getElementsByClassName("modal-video-body").style = styleModalVideoBody;
    document.getElementsByClassName("modal-video-inner").style = styleVideoInner;
    document.getElementsByClassName("modal-video-movie-wrap").style = stylewrapper;
    document.getElementsByTagName("iframe").style = iframeStyle;
    // document.getElementsByTagName('iframe')[0] .style = iframeStyle;
  }
 
  openModal () {
    this.setState({isOpen: true})
  }
 
  render () {
    return (
  <div className="header branding-header">
  {/* <Script
      url="https://ajax.googleapis.com/ajax/libs/jquery/1.8.3/jquery.min.js"
      onCreate={this.handleScriptCreate.bind(this)}
      onError={this.handleScriptError.bind(this)}
      onLoad={this.handleScriptLoad.bind(this)}
    /> */}
    <div className="content-wrapper">
        <div className="content">
            <h1>JAIDE au service des populations locales en difficulté</h1>
            <p>Présentation de l'association en vidéo</p>
            <button onClick={this.openModal} className="btn">Voir la vidéo</button>
        </div>
    </div>   
    <div className="branding">
        <img src="http://blog.associationjaide.fr/wp-content/uploads/2020/05/hands1.png" alt=""/>
    </div>
    <ModalVideo channel='youtube' className="video" isOpen={this.state.isOpen} videoId='FsKvClmQJVk' onClose={() => this.setState({isOpen: false})} />   
  
  </div>
    )
  }
}

// export default HeaderBranding
export default HeaderBranding